<style>
    /* Set the page size to A4 and the orientation to landscape */
    @page {
        size: A4 landscape;
    }

    /* Set the font size to 12px for printing */
    @media print {
        body {
            font-size: 12px;
        }
    }

    #logo {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 200px;
        height: 100px;
    }

    #title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    #no-border {
        border: none;
    }

    td, th {
        border: 2px solid #000000;
        text-align: left;
        padding: 8px;
    }

    .remove_border tr th{
        border: none;
    }

    #table-list {
        margin-top: 80px;
    }

    #info {
        margin-top: 20px;
        width: 40%;
        float: left;
        margin-bottom: 20px;
    }

    .space-for-logo {
        width: 200px;
        height: 100px;
    }
</style>

<template>
    <div>
        <!-- <img :src="logoSDN" id="logo" /> -->
        <p id="title">Canvas - Unloading</p>
        <div class="space-for-logo"></div>
        <div id="info">
            <table class="remove_border">
                <tr>
                    <th>Sales</th>
                    <th>:</th>
                    <th>{{ selectedSales }}</th>
                </tr>
                <tr>
                    <th>Warehouse</th>
                    <th>:</th>
                    <th>{{ selectedWarehouse }}</th>
                </tr>
                <tr>
                    <th>Loading Date</th>
                    <th>:</th>
                    <th>{{ formatDate(loadingDate) }}</th>
                </tr>
                <tr>
                    <th>Item Request Document</th>
                    <th>:</th>
                    <th>{{ reqCode }}</th>
                </tr>
                <tr>
                    <th>Unloading Date</th>
                    <th>:</th>
                    <th>{{ formatDate(unloadingDate) }}</th>
                </tr>
                <tr>
                    <th>Unloading Document Number</th>
                    <th>:</th>
                    <th>{{ unlCode }}</th>
                </tr>
            </table>
        </div>
        <table id="table-list">
            <thead>
                <tr>
                    <th style="text-align: center" colspan="9">
                        Rincian Barang Yang Dikembalikan
                    </th>
                </tr>
                <tr></tr>
                <tr>
                    <th width="29%">SKU Data</th>
                    <th width="7%">HU</th>
                    <th width="5%">Qty</th>
                    <th width="10%">Batch</th>
                    <th width="10%">Exp. Date</th>
                    <th width="7%">Status</th>
                    <th width="7%">HU</th>
                    <th width="7%">Qty</th>
                    <th width="23%">Storage Area</th>
                </tr>
            </thead>

            <tbody>
                <template v-for="(dataLine, index) in dataLines">
                    <!-- first row -->
                    <tr style="padding-bottom: 5px" :key="index">
                        <td class="align-top"
                            :rowspan="calculateTotalPutawayLines(dataLine)">
                            {{ dataLine.item_name }}
                        </td>
                        <td class="align-top"
                            :rowspan="dataLines[index].add_line[0].putaway_line.length">
                            {{ dataLines[index].add_line[0].check_selected_hu }}
                        </td>
                        <td class="align-top"
                            :rowspan="dataLines[index].add_line[0].putaway_line.length">
                            {{ dataLines[index].add_line[0].check_qty }}
                        </td>
                        <td class="align-top"
                            :rowspan="dataLines[index].add_line[0].putaway_line.length">
                            {{ dataLines[index].add_line[0].batch }}
                        </td>
                        <td class="align-top"
                            :rowspan="dataLines[index].add_line[0].putaway_line.length">
                            {{ formatDate(dataLines[index].add_line[0].exp_date) }}
                        </td>
                        <td class="align-top"
                            :rowspan="dataLines[index].add_line[0].putaway_line.length">
                            {{ dataLines[index].add_line[0].selected_status }}
                        </td>
                        <td class="td vs-table--td">
                            {{ dataLines[index].add_line[0].putaway_line[0].selected_hu_putaway }}
                        </td>
                        <td class="td vs-table--td">
                            {{ dataLines[index].add_line[0].putaway_line[0].putaway_qty }}
                        </td>
                        <td class="td vs-table--td">
                            {{ dataLines[index].add_line[0].putaway_line[0].selected_warehouse_area }}
                        </td>
                    </tr>

                    <!-- add lines -->
                    <template v-for="(addLine, indexAddLine) in dataLines[index].add_line">
                        <tr style="padding-bottom: 5px;" :key="index + '-' + indexAddLine" v-if="indexAddLine > 0">
                            
                            <td class="align-top"
                                :rowspan="dataLines[index].add_line[indexAddLine].putaway_line.length">
                                {{ dataLines[index].add_line[indexAddLine].check_selected_hu }}
                            </td>
                            <td class="align-top"
                                :rowspan="dataLines[index].add_line[indexAddLine].putaway_line.length">
                                {{ dataLines[index].add_line[indexAddLine].check_qty }}
                            </td>
                            <td class="align-top"
                                :rowspan="dataLines[index].add_line[indexAddLine].putaway_line.length">
                                {{ dataLines[index].add_line[indexAddLine].batch }}
                            </td>
                            <td class="align-top"
                                :rowspan="dataLines[index].add_line[indexAddLine].putaway_line.length">
                                {{ formatDate(dataLines[index].add_line[indexAddLine].exp_date) }}
                            </td>
                            <td class="align-top"
                                :rowspan="dataLines[index].add_line[indexAddLine].putaway_line.length">
                                {{ dataLines[index].add_line[indexAddLine].selected_status }}
                            </td>
                            <td class="td vs-table--td">
                                {{ dataLines[index].add_line[indexAddLine].putaway_line[0].selected_hu_putaway }}
                            </td>
                            <td class="td vs-table--td">
                                {{ dataLines[index].add_line[indexAddLine].putaway_line[0].putaway_qty }}
                            </td>
                            <td class="td vs-table--td">
                                {{ dataLines[index].add_line[indexAddLine].putaway_line[0].selected_warehouse_area }}
                            </td>
                        </tr>

                        <!-- putaway lines -->
                        <template v-for="(putawayLine, indexPutawayLine) in dataLines[index].add_line[indexAddLine].putaway_line">
                            <tr style="padding-bottom: 5px;" :key="index + '-' + indexAddLine + '-' + indexPutawayLine" v-if="indexPutawayLine > 0">
                                <td class="td vs-table--td">
                                    {{ dataLines[index].add_line[indexAddLine].putaway_line[indexPutawayLine].selected_hu_putaway }}
                                </td>
                                <td class="td vs-table--td">
                                    {{ dataLines[index].add_line[indexAddLine].putaway_line[indexPutawayLine].putaway_qty }}
                                </td>
                                <td class="td vs-table--td">
                                    {{ dataLines[index].add_line[indexAddLine].putaway_line[indexPutawayLine].selected_warehouse_area }}
                                </td>
                            </tr>
                        </template>
                    </template>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import moment from "moment";
// import moment from "moment-timezone";
// import logoSDN from "../../../assets/images/logo/logo-sdn.png";

export default {
    name: "PrintPdf",
    data() {
        return {
            id: null,
            reqCode: null,
            unlCode: null,
            selectedWarehouse: null,
            selectedSales: null,
            loadingDate: null,
            unloadingDate: null,
            dataLines: [
                {
                    unloading_id: 0,
                    item_name: null,
                    item_code: null,
                    total_length: 0,

                    add_line: [{
                        check_id: 0,
                        selected_status: null,
                        check_selected_hu: null,
                        check_qty: 0,
                        batch: null,
                        exp_date: null,

                        putaway_line: [{
                            putaway_id:	0,
                            selected_hu_putaway: null,
                            putaway_qty: 0,
                            selected_warehouse_area: null,
                        }]
                    }]
                }
            ],
            // logoSDN: logoSDN,
            showInfo: false,
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            console.log(this.id)
            this.print();
        }
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
            };
        },
        async print() {
            await this.getData()
            await window.print()
        },
        async getData() {
            this.$vs.loading();
            let resp = await this.$http.get(
                "/api/v1/canvas/print-complete-form/" + this.id
            );
            if (resp.code == 200) {
                this.reqCode = resp.data.request_doc
                this.unlCode = resp.data.unloading_doc
                this.selectedWarehouse = resp.data.selected_warehouse.label
                this.selectedSales = resp.data.selected_sales.label
                this.loadingDate = moment(resp.data.loading_date).toDate()
                this.unloadingDate = moment(resp.data.unloading_date).toDate()
                this.dataLines = resp.data.data_lines
                for (let i = 0; i < this.dataLines.length; i++) {
                    for (let j = 0; j < this.dataLines[i].add_line.length; j++) {
                        for (let k = 0; k < this.dataLines[i].add_line[i].putaway_line.length; k++) {
                            this.dataLines[i].total_length = this.dataLines[i].add_line[i].putaway_line.length;
                        }
                    }
                }

                this.$vs.loading.close();
            } else {
                this.$vs.loading.close();
            }
        },
        calculateTotalPutawayLines(dataLine) {
            let totalLines = 0;
            for (let i = 0; i < dataLine.add_line.length; i++) {
                totalLines += dataLine.add_line[i].putaway_line.length;
            }
            return totalLines;
        },
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
    },
}
</script>